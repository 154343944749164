* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: 'Radio Canada', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 20px 40px;
}

a {
	text-decoration: none;
	color: black;
}
